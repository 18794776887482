





























































import PageTitle from '@/components/PageTitle.vue'
import { InputSetups } from '@/mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

@Component({
  components: { PageTitle }
})
export default class CatalogIntegration extends Mixins(InputSetups, UseFields) {

  chatCatalogVisibilityOptions(): Array<SelectOptionData> {
    return [
      {
        label: this.$t('chat_catalog_visibility_default').toString(),
        value: 'Default'
      },
      {
        label: this.$t('chat_catalog_visibility_none').toString(),
        value: 'None'
      }, {
        label: this.$t('chat_catalog_visibility_visible').toString(),
        value: 'Visible'
      },
    ]
  }

  chatCatalogCategoryOptions(): Array<SelectOptionData> {
    return [
      { label: this.$t('chat_catalog_category_none').toString(), value: 'None' },
      { label: this.$t('chat_catalog_category_darknet').toString(), value: 'Darknet' }, // Даркнет
      { label: this.$t('chat_catalog_category_courses').toString(), value: 'Courses' }, // Курсы и гайды
      { label: this.$t('chat_catalog_category_programming').toString(), value: 'Programming' }, // Программирование
      { label: this.$t('chat_catalog_category_web_design').toString(), value: 'WebDesign' }, // Web дизайн
      { label: this.$t('chat_catalog_category_art_design').toString(), value: 'ArtDesign' }, // Искусство и дизайн
      { label: this.$t('chat_catalog_category_beauty').toString(), value: 'Beauty' }, // Бьюти, Мода и красота
      { label: this.$t('chat_catalog_category_bets_casino').toString(), value: 'BetsCasino' }, // Ставки и казино
      { label: this.$t('chat_catalog_category_blogs').toString(), value: 'Blogs' }, // Блоги
      { label: this.$t('chat_catalog_category_books').toString(), value: 'Books' }, // Книги
      { label: this.$t('chat_catalog_category_business').toString(), value: 'Business' }, // Бизнес и стартапы
      { label: this.$t('chat_catalog_category_career').toString(), value: 'Career' }, // Карьера
      { label: this.$t('chat_catalog_category_cryptocurrencies').toString(), value: 'Cryptocurrencies' }, // Криптовалюты
      { label: this.$t('chat_catalog_category_economy_finance').toString(), value: 'EconomyFinance' }, // Экономика и финансы
      { label: this.$t('chat_catalog_category_education').toString(), value: 'Education' }, // Образование
      { label: this.$t('chat_catalog_category_erotic').toString(), value: 'Erotic' }, // Эротика
      { label: this.$t('chat_catalog_category_facts').toString(), value: 'Facts' }, // Факты
      { label: this.$t('chat_catalog_category_family_children').toString(), value: 'FamilyChildren' }, // Семья и дети
      { label: this.$t('chat_catalog_category_food_drink').toString(), value: 'FoodDrink' }, // Еда и напитки
      { label: this.$t('chat_catalog_category_games').toString(), value: 'Games' }, // Игры
      { label: this.$t('chat_catalog_category_shock').toString(), value: 'Shock' }, // Шок-контент
      { label: this.$t('chat_catalog_category_healthy_lifestyle').toString(), value: 'HealthyLifestyle' }, // ЗОЖ
      { label: this.$t('chat_catalog_category_home_architecture').toString(), value: 'HomeArchitecture' }, // Дом и архитектура
      { label: this.$t('chat_catalog_category_humor_entertainment').toString(), value: 'HumorEntertainment' }, // Юмор и развлечения
      { label: this.$t('chat_catalog_category_legal').toString(), value: 'Legal' }, // Право
      { label: this.$t('chat_catalog_category_linguistics').toString(), value: 'Linguistics' }, // Лингвистика
      { label: this.$t('chat_catalog_category_marketing_pr').toString(), value: 'MarketingPr' }, // Маркетинг, PR, реклама
      { label: this.$t('chat_catalog_category_medicine').toString(), value: 'Medicine' }, // Медицина
      { label: this.$t('chat_catalog_category_motivation_quotes').toString(), value: 'MotivationQuotes' }, // Мотивация и цитаты
      { label: this.$t('chat_catalog_category_movies').toString(), value: 'Movies' }, // Видео и фильмы
      { label: this.$t('chat_catalog_category_music').toString(), value: 'Music' }, // Музыка
      { label: this.$t('chat_catalog_category_mature_animals').toString(), value: 'NatureAnimals' }, // Природа и животные
      { label: this.$t('chat_catalog_category_news_media').toString(), value: 'NewsMedia' }, // Новости и медиа
      { label: this.$t('chat_catalog_category_other').toString(), value: 'Other' }, // Другое
      { label: this.$t('chat_catalog_category_picture').toString(), value: 'Picture' }, // Картинки
      { label: this.$t('chat_catalog_category_politics').toString(), value: 'Politics' }, // Политика
      { label: this.$t('chat_catalog_category_psychology').toString(), value: 'Psychology' }, // Психология
      { label: this.$t('chat_catalog_category_real_estate').toString(), value: 'RealEstate' }, // Недвижимость
      { label: this.$t('chat_catalog_category_religion_spirituality').toString(), value: 'ReligionSpirituality' }, // Религия и эзотерика
      { label: this.$t('chat_catalog_category_sales').toString(), value: 'Sales' }, // Продажи
      { label: this.$t('chat_catalog_category_social_network').toString(), value: 'SocialNetwork' }, // Соцсети
      { label: this.$t('chat_catalog_category_sports').toString(), value: 'Sports' }, // Спорт
      { label: this.$t('chat_catalog_category_technology').toString(), value: 'Technology' }, // Технологии
      { label: this.$t('chat_catalog_category_soft').toString(), value: 'Soft' },     // Софт и приложения
      { label: this.$t('chat_catalog_category_telegram').toString(), value: 'Telegram' }, // Telegram
      { label: this.$t('chat_catalog_category_transport').toString(), value: 'Transport' }, // Транспорт
      { label: this.$t('chat_catalog_category_travel').toString(), value: 'Travel' } // Путешествия
    ]
  }

}
